.blur-background {
  /* backdrop-filter: blur(8px); */
  background-color: rgba(0, 0, 0, 0.3);
}

.ql-editor {
  min-height: 350px;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.pagination li {
  margin: 0 4px;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  padding: 4px 4px;
  color: #000;
  border-bottom: 1px solid #e6e6e6;
  /* border-radius: 50%; */
  transition: background-color 0.3s;
  /* background-color: #080606; */
}

.pagination li.active {
  /* background-color: #00000030; */
  color: #000;
  border-bottom: 1px solid #000;
}

.pagination li:hover {
  border-bottom: 1px solid #000;
  color: black;
}
